import React from "react";
import { Helmet } from 'react-helmet';
import { headData } from '../mock/data';
import App from '../components/App';
import '../style/main.scss';
import 'bootstrap/dist/css/bootstrap.min.css';




export default function Home() {
  const { title, lang, description } = headData;

  return (<>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title || 'Gatsby Simplefolio'}</title>
      <html lang={lang || 'en'} />
      <meta name="description" content={description || 'Gatsby Simplefolio'} />
    </Helmet>
    <App />
  </>);
}
