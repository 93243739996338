import React, { useContext, useState, useEffect } from 'react';
import PortfolioContext from '../../context/context';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';


const Footer = () => {
    const { footer } = useContext(PortfolioContext);
    const { networks } = footer;

    return (
        <footer className="footer navbar-static-bottom">
            <Container>
                <span className="back-to-top">
                    <Link to="hero" smooth duration={1000}>
                        <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
                    </Link>
                </span>
                <div className="social-links">
                    {networks &&
                        networks.map((network) => {
                            const { id, name, url } = network;
                            return (
                                <a
                                    key={id}
                                    href={url || 'https://github.com/cobidev/gatsby-simplefolio'}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    aria-label={name}
                                >
                                    <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                                </a>
                            );
                        })}
                </div>
                <hr />
                <p className="footer-text">
                    © {new Date().getFullYear()} - developed by Richard Romero
                </p>

            </Container>
        </footer>
    )
};

export default Footer;