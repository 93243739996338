import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';

const Projects = () => {
    const { projects } = useContext(PortfolioContext);
    const [isDesktop, setIsDesktop] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true);
            setIsMobile(false);
        } else {
            setIsMobile(true);
            setIsDesktop(false);
        }
    }, []);

    return (<section id="projects">
        <Container>
            <div className="project-wrapper">
                <Title title="Projects" />
                {projects.map((project) => {
                    const { title, info, info2, url, repo, img, id } = project;
                    return (
                        <Row key={id}>
                            <Col lg={4} sm={12}>
                                <Fade left={isDesktop}
                                    bottom={isMobile}
                                    duration={1000}
                                    delay={500}
                                    distance="30px"
                                >
                                    <div className="project-wrapper-text">
                                        <h3 className="project-wrapper-title">{title || 'Project Title'}</h3>
                                        <div>
                                            <p>
                                                {info || 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi neque, ipsa animi maiores repellendu distinctioaperiam earum dolor voluptatum consequatur blanditiis inventore debitis fuga numquam voluptate architecto itaque molestiae.'}
                                            </p>
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col lg={8} sm={12}>
                                <Fade
                                    right={isDesktop}
                                    bottom={isMobile}
                                    duration={1000}
                                    delay={1000}
                                    distance="30px"
                                >
                                    <div className="project-wrapper-image">
                                        <Tilt
                                            options={{
                                                reverse: false,
                                                max: 8,
                                                perspective: 1000,
                                                scale: 1,
                                                speed: 300,
                                                transition: true,
                                                axis: null,
                                                reset: true,
                                                easing: 'cubic-bezier(.03,.98,.52,.99)',
                                            }}
                                        >
                                            <div className="thumbnail rounded">
                                                <a href={url}>
                                                    <img src={img} />
                                                </a>
                                            </div>
                                        </Tilt>

                                    </div>
                                </Fade>
                            </Col>
                        </Row>
                    );
                })}
            </div>
        </Container>
    </section>);
};

export default Projects;