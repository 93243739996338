import { nanoid } from 'nanoid';


export const headData = {
    title: 'Romrick Dot Com', // e.g: 'Name | Developer'
    lang: '', // e.g: en, es, fr, jp
    description: 'A look into my life', // e.g: Welcome to my website
  };

  export const heroData = {
    title: '',
    name: 'Richard Romero',
    subtitle: 'The Musician, Software Engineer, Bartender',
    cta: '',
  };

  export const aboutData = {
    img: '/images/whitetshirt.jpg',
    paragraphOne: 'I\'m a fullstack developer with a passion to create beautiful content.',
    paragraphTwo: 'I love to create cocktails and music in my spare time while I dream up and build out the next big app.',
    paragraphThree: 'My drive to continuously learn and collaborate makes me the perfect developer to have on any team.'
  };

  // PROJECTS DATA
export const projectsData = [
  // {
  //   id: "1",
  //   img: 'images/bobaquiz.PNG',
  //   title: 'Boba Quiz',
  //   info: 'A quirky personality quiz coded from scratch using VueJS, HTML, and CSS. Find out what kind of boba drink you are!',
  //   info2: '',
  //   url: 'http://bobaquiz.romrick.com',
  //   repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  // },
  {
    id: "1",
    img: 'images/nightowl.png',
    title: 'Night Owl',
    info: 'This is passion project built with an Ionic/Angular frontend and a NodeJS serverless backend. I utilize Yelp\'s API to fetch the best bars in a walking distance near you.',
    info2: '',
    url: 'https://nightowlapp.xyz',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  }
];

export const contactData = {
  cta: 'Impressed yet? Let\'s get in touch!',
  btn: 'Email Me',
  btn2: 'Resume',
  email: 'romrick4@gmail.com'
};

export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'facebook',
      url: 'http://www.facebook.com/romrick4',
    },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'http://www.instagram.com/my_real_name_is_richard',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/richard-romero-2aba6a223/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/romrick4',
    },
  ],
};
