import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';

const Header = () => {
    const { hero } = useContext(PortfolioContext);
    const { title, name, subtitle, cta } = hero;

    const [isDesktop, setIsDesktop] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true);
            setIsMobile(false);
        } else {
            setIsDesktop(false);
            setIsMobile(true);
        }
    }, []);

    return (
        <section id="hero" className="jumbotron">
            <Container>
                <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
                    <h1 className="hero-title">
                        {title || 'Hi, my name is'}{' '}
                        <span className="text-color-main">{name || 'Your Name'}</span>
                        <br />
                        {subtitle || "I'm the Unknown Developer."}
                    </h1>
                </Fade>

            </Container>
        </section>
    );
};

export default Header;