import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import Fade from 'react-reveal/Fade';


const Contact = () => {
    const { contact } = useContext(PortfolioContext);
    const { cta, btn, btn2, email } = contact;

    return (
        <section id="contact">
            <Container>
                <Title title="Contact" />
                <Fade bottom duration={1000} delay={800} distance="30px">
                    <div className="contact-wrapper">
                        <p className="contact-wrapper-text">
                            {cta || "Contact Me"}
                        </p>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cta-btn cta-btn--resume email-me"
                            href={email ? `mailto:${email}` : 'https://github.com/cobidev/react-simplefolio'}
                            style={{marginRight: '20px'}}
                        >
                            {btn || "Email"}
                        </a>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cta-btn cta-btn--resume my-resume"
                            href="resume/Richard_Romero_Resume_2024.pdf"
                        >
                            {btn2 || "Let's Talk"}
                        </a>

                    </div>
                </Fade>
            </Container>
        </section>
    );
};

export default Contact;